import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;
    padding: ${variables.sectionPadding};

    .headline {
        font-size: 1.7em;
        font-weight: 700;
        color: #262626;
        text-align: center;
    }

    .subheadline {
        font-size: 1.2em;
        font-weight: 400;
        color: #262626;
        margin-top: .5em;
        text-align: center;
    }

    .description {
        margin-top: 1em;
        display: -webkit-box;
        white-space: normal;
        max-height: 96px;
        overflow: hidden;
        
        &.expanded {
            max-height: 100%;            
        }
    }

    .section-control {
        padding-top: 2em;

        .expand-state {
            .button-icon-after {
                transform: scaleY(-1);
            }
        }
    }

    @media all and (max-width: ${variables.mobileL}) {
        padding: ${variables.sectionPaddingMobile};
    }
`;
