import { FunctionComponent, useRef, useState } from 'react';
import getConfig from 'next/config';
import classnames from 'classnames';

import Button, { ButtonElements, ButtonIconPositions, ButtonIconTypes, ButtonVariants } from 'components/layout/Button';
import HtmlStyledContent from 'components/layout/HtmlStyledContent';
import LayoutContainer from 'components/layout/LayoutContainer';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionCheckoutVariantDefault: FunctionComponent<Props> = ({ translations }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const headlineRef = useRef(null);

    const { publicRuntimeConfig } = getConfig();
    const isPL = publicRuntimeConfig.APP_LOCALE === 'pl';

    const { headline, subheadline, description } = translations;
    const expandable = description.split(/\n/).length > 1;

    const onExpandClick = (): void => {
        setIsExpanded(!isExpanded);

        if (isExpanded) {
            headlineRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    };

    return (
        <StyledComponent className="public-section-checkout-variant-default">
            <LayoutContainer>
                <div
                    className="headline"
                    ref={headlineRef}
                    dangerouslySetInnerHTML={{ __html: headline }}
                />
                {subheadline && (
                    <div
                        className="subheadline"
                        dangerouslySetInnerHTML={{ __html: subheadline }}
                    />
                )}
                <HtmlStyledContent
                    className={classnames(
                        'description',
                        {
                            expanded: isExpanded,
                        }
                    )}
                    html={description}
                />
                {expandable && (
                    <div className="section-control">
                        <Button
                            onClick={() => onExpandClick()}
                            icon={{
                                value: '/images/home/arrow-down.svg',
                                position: ButtonIconPositions.After,
                                type: ButtonIconTypes.Image,
                            }}
                            variant={ButtonVariants.Outline}
                            element={ButtonElements.Button}
                            className={classnames(isExpanded && 'expand-state')}
                        >
                            {isPL
                                ? isExpanded
                                    ? 'Zwiń' : 'Czytaj dalej'
                                : isExpanded
                                    ? 'Collapse' : 'Read more'
                            }
                        </Button>
                    </div>
                )}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicSectionCheckoutVariantDefault;
